.mainPageCards {
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 5%;
  background: linear-gradient(
    300deg,
    #013b31,
    #378058,
    #185a81,
    #8a1e5a,
    #a9141b
  );

  background-size: 300% 300%;
  border-radius: 0px 0px 10px 10px;
  animation: gradient-animation 30s ease infinite;
  color: white !important;
  transition: 0.3s ease-in;
  box-shadow: 1px 5px 18px 2px rgba(0, 0, 0, 0.419);
}

.mainCard {
  margin: 0 auto;
}

.mainPageCards h5 {
  font-size: 2em;
}

.mainPageSvg {
  height: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  flex-direction: column;
}

#visual1 path {
  animation: 18s ease-in svgFillColorizer1 infinite alternate-reverse;
}

#visual2 path {
  animation: 10s ease-in svgFillColorizer2 infinite alternate-reverse;
}

#visual3 path {
  transform: scale(0.5);
  animation: 5s ease-in svgFillColorizer3 infinite alternate-reverse;
}

#visual4 path {
  animation: 13s ease-in svgFillColorizer4 infinite alternate-reverse;
}

#visual4 {
  margin-top: 10vh;
  margin-left: 25vw;
}

.mainPageContainer {
  height: 100%;
  animation: fadeIn 0.3s ease-in;
}

.mainPageContainer h1 {
  font-family: Julius Sans One;
}

.resultCard {
  margin: auto;
  width: 100vw !important;
}

.resultImage {
  margin: auto;
  border-radius: 20px;
  border: 1px black solid;
  margin-top: 2vh;
  max-height: 65%;
}

.resultName {
  margin: auto;
}
@keyframes svgFillColorizer1 {
  0% {
    fill: #013b3184;
  }
  25% {
    fill: #37805881;
  }
  50% {
    fill: #185b8184;
  }
  75% {
    fill: #8a1e59aa;
  }
  100% {
    fill: #4d1e20b5;
    d: path(
      "M67.9 -85.7C87.2 -93.3 101.3 -73 135.7 -47.4C170.1 -21.8 224.6 9.2 234.8 43.5C245 77.8 210.9 115.5 185.5 180.8C160.2 246.1 143.5 338.9 114 313.4C84.5 287.9 42.3 143.9 3.2 87.5C-35.8 31 -71.5 62 -87.7 64.3C-103.9 66.6 -100.4 40.3 -113.6 10.6C-126.8 -19.1 -156.7 -52.1 -177.5 -112.1C-198.4 -172 -210.3 -258.9 -179.2 -245.9C-148.1 -232.8 -74.1 -119.9 -24.9 -81.2C24.3 -42.5 48.7 -78 67.9 -85.7"
    );
  }
}

@keyframes svgFillColorizer2 {
  0% {
    fill: #013b3182;
  }
  25% {
    fill: #3780587c;
  }
  50% {
    fill: #185b8182;
  }
  75% {
    fill: #8a1e597e;
  }
  100% {
    fill: #4d1e2089;

    d: path(
      "M117.4 -204.2C144.8 -187.6 154.7 -141.3 174.5 -102.3C194.4 -63.3 224.2 -31.7 225.5 0.7C226.8 33.2 199.6 66.3 176.5 99.7C153.4 133 134.4 166.5 105.7 183.6C77 200.7 38.5 201.4 -2.6 205.8C-43.7 210.3 -87.3 218.6 -106.8 196.2C-126.3 173.8 -121.6 120.6 -136.6 82.8C-151.6 45 -186.3 22.5 -197.7 -6.6C-209.1 -35.7 -197.2 -71.3 -174.8 -96.3C-152.4 -121.3 -119.4 -135.5 -88.6 -150.2C-57.7 -164.9 -28.8 -179.9 8.1 -193.9C45 -207.9 90 -220.9 117.4 -204.2"
    );
  }
}

@keyframes svgFillColorizer3 {
  0% {
    fill: #013b31;
  }
  25% {
    fill: #378058;
  }
  50% {
    fill: #185a81;
  }
  75% {
    fill: #8a1e5a;
  }
  100% {
    fill: #4d1e20;
    d: path(
      "M92.7 -145.9C125.8 -141.5 162 -128 170.1 -102.2C178.2 -76.3 158.1 -38.2 144.5 -7.8C131 22.5 123.9 45 123.4 84C122.9 122.9 129 178.4 109.5 189.8C90 201.2 45 168.6 7.3 155.9C-30.3 143.2 -60.7 150.4 -89.8 144.6C-119 138.7 -147 119.9 -157.3 93.6C-167.6 67.3 -160.3 33.7 -158.9 0.8C-157.4 -32 -161.9 -64 -147.9 -83.9C-133.9 -103.8 -101.4 -111.7 -73.7 -119.2C-46 -126.7 -23 -133.8 3.4 -139.8C29.8 -145.7 59.7 -150.3 92.7 -145.9"
    );
  }
}

@keyframes svgFillColorizer4 {
  0% {
    fill: #013b31;
  }
  25% {
    fill: #378058;
  }
  50% {
    fill: #185a81;
  }
  75% {
    fill: #8a1e5a;
  }
  100% {
    fill: #4d1e20;
    d: path(
      "M129.9 -124.9C167.4 -92.4 196.2 -46.2 186.4 -9.8C176.5 26.5 128 53 90.5 77.5C53 102 26.5 124.5 -12.1 136.6C-50.7 148.7 -101.4 150.4 -136.5 125.9C-171.7 101.4 -191.3 50.7 -190.4 0.9C-189.5 -48.8 -167.9 -97.6 -132.7 -130.1C-97.6 -162.6 -48.8 -178.8 -1.3 -177.5C46.2 -176.2 92.4 -157.4 129.9 -124.9"
    );
  }
}

@keyframes svgFillColorizer5 {
  0% {
    fill: #013b31;
  }
  25% {
    fill: #378058;
  }
  50% {
    fill: #185a81;
  }
  75% {
    fill: #8a1e5a;
  }
  100% {
    fill: #4d1e20;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 1558px) {
  .mainCardContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 428px) {
  .mainPageCards:hover {
    transform: none;
  }
  .mainPageCards {
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 5%;
    /* margin-left:5%;
    margin-right: 5%; */
    background: linear-gradient(
      300deg,
      #013b31,
      #378058,
      #185a81,
      #8a1e5a,
      #a9141b
    );
    background-size: 300% 300%;
    border-radius: 0px 0px 10px 10px;
    animation: gradient-animation 30s ease infinite;
    color: white !important;
    transition: 0.2s ease-in;
    box-shadow: 1px 5px 18px 2px rgba(0, 0, 0, 0.419);
  }

  .mainPageSvg {
    height: 100%;
    width: 75vw;
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: column;
    fill: red;
    animation: 3s ease-in svgColorizer infinite;
  }
}
