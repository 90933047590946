
.testPageCards h5{
  margin-top: 5%;
  transition: .3s ease-in-out;
  text-align: center !important;
  border-radius: 5px;
  width: 30vw;
  background-color:rgb(32, 32, 32);
  padding: 5vh 2vw;
  margin: auto;
  color: white;
  box-shadow: 2px 5px 18px 1px rgba(0, 0, 0, 0.82);
  -webkit-box-shadow: 2px 5px 18px 1px rgba(0, 0, 0, 0.82);
  -moz-box-shadow: 2px 5px 18px 1px rgba(0, 0, 0, 0.82);
}





.testBackground{
  background: linear-gradient(
    300deg,
    #013b3171,
    #3780586f,
    #185b816c,
    #8a1e5966,
    #a9141b5b
  );
  background-size: 300% 300%;
  animation: gradient-animation 30s ease infinite;
}

.shareButton{
  background-color: #fbfbfbb5;
  border-radius: 10px;
  position: absolute;
  margin-left: 2vw;
}
.shareButton svg{
  background-color: #00000000;
  border-radius: 10px;
  height: 7vh;
}


.answer {
  padding-left: 0.2em;
}

.testPageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  background: linear-gradient(
    300deg,
    #013b3171,
    #3780586f,
    #185b816c,
    #8a1e5966,
    #a9141b5b
  );
  background-size: 300% 300%;
  animation: gradient-animation 30s ease infinite;
}

#linkShare {
  display: none;
}

.nextQuestionButton{
  border: solid white;
  width: 10vw;
  padding: 2vh;
  margin: auto;
  margin-bottom: 2vh;
}

.questionContainer{
  margin-top: 2%;
  height: auto;

  display: flex;
  flex-direction: column;
}

.answerOptions{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  height: auto;
  width: 80%;
  padding-bottom: 5%;
  flex-wrap: wrap;
}

.answerSelection span{
  background-color: rgb(32, 32, 32);
  width: 30vw;
  height: 20vh;
  padding: 2vw;
  justify-content: space-between;
  font-size: 1.5em;
  user-select: none;
  overflow: auto;
  border-radius: 4px;
  color: white;
  box-shadow: 2px 5px 12px 1px rgba(0, 0, 0, 0.82);
  transition: .3s ease-in-out;

  align-items: flex-start; /* Allow height to adjust based on content */
  align-self: flex-start; /* Align each item individually */
  display: flex; /* Make the span a flex container */
}

input[type="radio"] {
  visibility: hidden;
}

.answerSelection input[type="radio"]:checked + span {
  background-color: #88ff48;
  color: black;
  transform: scale(1.2)
}

.fadeIn{
  
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn{
  0%{ filter: opacity(0)
  
  }

  100%{filter: opacity(1)}
}


@media screen and (max-width: 722px) {

  .shareButton svg{
    background-color: #00000000;
    border-radius: 10px;
    height: 5vh;
  }
  .nextQuestionButton{
    width: 35vw;
    margin: auto;
    margin-top: 2vh;
    font-size: 1.5em;
    pointer-events: none;
    margin-bottom: 2vh;
  }


  .questionContainer{
    margin-top: 2%;
    height: auto;
  
    display: flex;
    flex-direction: column;
  }



  .answerSelection span{
    background-color: rgb(32, 32, 32);
    width: 60vw;
    height: 15vh;
    padding: 2vw;
    justify-content: space-between;
    font-size: 1.2em;
    pointer-events: none;
    border-radius: 4px;
    color: white;
    box-shadow: 2px 5px 12px 1px rgba(0, 0, 0, 0.82);
    transition: .3s ease-in-out;
  
    align-items: flex-start; /* Allow height to adjust based on content */
    align-self: flex-start; /* Align each item individually */
    display: flex; /* Make the span a flex container */
  }

  .testPageCards h5{
    width: auto;
    margin: 5% 15%;
  }

}

@media screen and (max-width: 428px) {

  .shareButton svg{
    background-color: #00000000;
    border-radius: 10px;
    height: 5vh;
  }
  .nextQuestionButton{
    width: 35vw;
    margin: auto;
    margin-top: 2vh;
    font-size: 1.5em;
    margin-bottom: 2vh;
  }


  .questionContainer{
    margin-top: 2%;
    height: auto;
  
    display: flex;
    flex-direction: column;
  }


  .answerSelection span{
    background-color: rgb(32, 32, 32);
    width: 60vw;
    height: 15vh;
    padding: 2vw;
    justify-content: space-between;
    font-size: 1.2em;
    border-radius: 4px;
    color: white;
    box-shadow: 2px 5px 12px 1px rgba(0, 0, 0, 0.82);
    transition: .3s ease-in-out;
  
    align-items: flex-start; /* Allow height to adjust based on content */
    align-self: flex-start; /* Align each item individually */
    display: flex; /* Make the span a flex container */
  }

  .testPageCards h5{
    width: auto;
    margin: 5% 15%;
  }

}
