

.svgLinesBg {
  z-index: 2;
  margin-left: 20vw;
  margin-top: 20vh;
  height: 95vh;
  transform: scale(2);
  position: fixed;
  width: 100vw;
  background: linear-gradient(
    300deg,
    #013b31,
    #378058,
    #185a81,
    #8a1e5a,
    #a9141b
  );
  /* background-size: 300% 300%; */
  animation: gradient-animation 30s ease infinite;
}



.svgLinesBg svg {
  max-width: 100vw;
}

.masterPageContainer button {
  width: 15vw;
  margin: 20px auto;

  box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -webkit-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -moz-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
}

.createTestForm {
  position: relative;
  margin: auto;
  height: 83vh;
  width: 85vw;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 18px;
  border: none !important;
  z-index: 3;
  backdrop-filter: blur(2px);
  color: white;
}
.startCreateTest {
  box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  border-radius: 10px 10px 40px 40px;
  -webkit-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -moz-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  display: flex;
  height: 70vh;
  width: 85vw;
  flex-direction: row;
  animation: fadeIn 0.7s ease-in;
}

.startCreateTest p {
  width: 50%;
  margin: auto;
}

.startCreateTest input {
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 2em;
  border-bottom: 2px black solid;
  width: 30vw;
  margin: auto;
  border-radius: 4px;
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.798);
}
.createOptions {
  border-radius: 0px 10px 40px 0px;
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #0c0f18;
}

.tooltip {
  position:relative;
  text-align:left;
}

.tooltip h3 {margin:12px 0;}

.tooltip .top {
  min-width:200px;
  max-width:400px;
  top:-20px;
  left:50%;
  transform:translate(-30%,-100%);
  padding:10px 20px;
  color:#000000;
  background-color:#ffffff;
  font-weight:normal;
  font-size:14px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  display:none;
}

.tooltip:hover .top {
  display:block;
}

.tooltip .top i {
  position:absolute;
  top:100%;
  left:30%;
  margin-left:-15px;
  width:30px;
  height:15px;
  overflow:hidden;
}

.tooltip .top i::after {
  content:'';
  position:absolute;
  width:15px;
  height:15px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#ffffff;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}




#createCategories {
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 2em;
  border-bottom: 2px black solid;
  width: 30vw;
  margin: auto;
  border-radius: 4px;
  color: #ffffff !important;
  height: 7vh;
  background-color: rgba(0, 0, 0, 0.798);
  text-align: center;
}



.startCreateTest input::placeholder {
  color: white;
}

.resultsH2 {
  font-size: 3em !important;
  margin: auto;
  padding-bottom: 2vh;
  color: white;
}

.createTestForm input:focus {
  outline: none !important;
  border-bottom: rgb(255, 255, 255) solid;
  transition: border 0.2s ease-in;
  outline: none;
  box-shadow: none;
}

.customCard {
  /* box-shadow: 1px 1px 10px 1px; */
  height: 65vh;
  border-top: none !important;
  border-radius: 10px 10px 40px 40px;
  background: rgba(0, 0, 0, 0.058);
  color: rgb(255, 255, 255);
  max-height: 60vh;
  width: 25vw;
  max-width: 25vw;
  margin-right: 1vw;
  background: #020305c6;
}

.customCard:nth-of-type(1) {
  animation-delay: 0.5s;
}

.customCard:nth-of-type(2) {
  animation-delay: 1s;
}

.customCard:nth-of-type(3) {
  animation-delay: 1.5s;
}

.customCard:nth-of-type(4) {
  animation-delay: 2s;
}



.resultsFormContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 2vh;
  justify-content: space-between !important;
  animation: fadeIn 1s ease-in;

}


.customCard h3 {
  text-align: center;
  font-size: 2em !important;
  font-family: "Julius Sans One", sans-serif;
  padding: 1vh;
  height: 2.5vh;
  padding-top: 2vh;
  font-weight: bolder;
}

.placeholderImage {
  height: 40vh;
  width: 100%;
  box-shadow: 1px 5px 18px 2px rgba(0, 0, 0, 0.419);
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(255, 255, 255, 0.464);
}

#testNameInput input {
  text-align: center;
  color: white !important;
}
.resultInputContainer {
  width: 20vw !important;
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  margin-top: 4vh !important;
  margin: auto;
  /* opacity: 0; */
  /* transform: translateY(100%); */
  transition: transform 0.5s, opacity 0.5s;
}



.resultInputContainer input {
  width: 40% !important;
  height: 4vh;
  border: none;
  background-color: #020305;
  border-radius: 5px;
  margin-right: 0.5vw;
  color: rgb(255, 255, 255);
}

.resultInputContainer input:nth-of-type(3) {
  width: 60% !important;
  height: 4vh;
  border: none;
  background-color: #020305;
  border-radius: 5px;
  margin-right: 0.5vw;
  color: rgb(255, 255, 255);
}

.resultInputContainer input::placeholder {
  color: rgb(251, 251, 251);
}

h2 {
  color: rgba(255, 255, 255, 0.914);
  font-size: 3em !important;
  font-family: "Julius Sans One", sans-serif;
}

.mb-6 h2 {
  border-top: 2px rgba(0, 0, 0, 0.202) solid;
  width: 50vw;
  margin-bottom: 2vh;
  font-weight: 700;
}

.createTestForm h2 {
  font-size: 3em;
  text-align: center;
}

.createDescription {
  display: flex;
  flex-direction: row;
  height: 70vh;
  box-shadow: 1px 5px 18px 2px rgba(0, 0, 0, 0.419);
  border-radius: 10px 10px 40px 40px;
  background-color: rgba(0, 0, 0, 0.798);
  animation: fadeIn 0.7s ease-in;
  margin: auto;
  width: 70vw;
}

.createImage {
  background-size: 100% 100%;
  width: 50%;
  border-radius: 10px 00px 0px 40px;
}

.createImage h1 {
  color: red;
  height: 100%;
  text-align: center;
}

.descriptionFields {
  margin: auto;

  width: 50%;
}
.descriptionFields textarea {
  font-size: 1.5em;
  height: 15vh;
  width: 30vw !important;
  color: white;
  background-color: rgba(0, 0, 0, 0.798);
  border: none;
  border-radius: 5px 5px 5px 5px;
}

textarea:focus {
  border-bottom: #ffffff 2mm solid !important;
  outline: none !important;
  transition: border 0.2s ease-in;
  box-shadow: none;
}

.descriptionFields textarea:nth-of-type(2) {
  font-size: 1.5em !important;
  line-height: 1em;
  color: rgb(255, 255, 255);
}

.descriptionFields h2 {
  font-size: 2em !important;
  margin-top: 4vh;
  text-align: center;
}

textarea::placeholder {
  color: white;
}

.createTestForm p {
  text-align: center;
  max-width: 70vw;
  font-size: 2em;
  padding: 1em;
  font-family: "Julius Sans One", sans-serif;
}

.descriptionFields textarea:nth-of-type(1) {
  font-size: 1em;
  height: 5vh;
  text-align: start;
  padding: none;
  width: 100%;
  margin: auto;
}

.descriptionFields textarea:nth-of-type(2) {
  font-size: 1em !important;
  height: 10vh;
  text-align: start;
  width: 100%;
  
}

@keyframes cardEffect {
  0% {
    /* border: #013b31 ridge 10px; */
    box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
    -webkit-box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
    -moz-box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
  }
  25% {
    /* border: #378058 ridge 10px; */
  }
  50% {
    /* border: #185a81 ridge 10px; */
    box-shadow: -4px -2px 81px 6px rgba(0, 0, 0, 0.75) inset;
    -webkit-box-shadow: -4px -2px 81px 6px rgba(0, 0, 0, 0.75) inset;
    -moz-box-shadow: -4px -2px 81px 6px rgba(0, 0, 0, 0.75) inset;
  }
  75% {
    /* border: #8a1e5a ridge 10px; */
  }
  100% {
    /* border: #a9141b ridge 10px; */
    box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
    -webkit-box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
    -moz-box-shadow: -4px -2px 39px 4px rgba(0, 0, 0, 0.75) inset;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#line1 {
  animation: createBgAnimation1 30s linear infinite alternate;
}

#line2 {
  animation: createBgAnimation2 30s linear infinite alternate;
}

#line3 {
  animation: createBgAnimation3 30s linear infinite alternate;
}

#line4 {
  animation: createBgAnimation4 30s linear infinite alternate;
}

#line5 {
  animation: createBgAnimation5 30s linear infinite alternate;
}

@keyframes createBgAnimation1 {
  0% {
    d: path(
      "M875.64-78.29C708.67-50.98 627.01 352.66 356.45 355.8 85.88 358.94-29.02 227.96-162.75 227"
    );
  }
  100% {
    d: path(
      "M926.38-3.2C778.8 67.99 846.06 543.09 567.89 545.51 289.72 547.93 41.81 286.42-149.09 282.31"
    );
  }
}

@keyframes createBgAnimation2 {
  0% {
    d: path(
      "M778.44-4.78C623.85 7.9 515.73 320.22 250.05 320.38-15.63 320.54-135.99 111.53-278.34 107.58"
    );
  }
  100% {
    d: path(
      "M903.28-95.48C774.98-65.84 813.24 275.11 529.53 275.64 245.83 276.17-24.78 81.26-217.96 79.64"
    );
  }
}

@keyframes createBgAnimation3 {
  0% {
    d: path(
      "M1234.37-62.69C1083.09-18.11 1048.4 399.84 816.84 410.19 585.28 420.54 608.08 340.19 399.32 340.19 190.55 340.19 87.63 409.95-18.21 410.19"
    );
  }
  100% {
    d: path(
      "M1227.95-36.42C1117.97-34.48 1020.54 105.68 811.58 105.75 602.61 105.82 603.39 35.75 395.2 35.75 187.02 35.75 84.38 105.51-21.17 105.75"
    );
  }
}

@keyframes createBgAnimation4 {
  0% {
    d: path(
      "M717.07-106.36C621.65-91.92 673.74 119.56 416.52 135.91 159.3 152.26-21.18 388.16-184.59 393.51"
    );
  }
  100% {
    d: path(
      "M908.12-70.27C799.52-63.64 835.26 129.35 526.06 138.15 216.87 146.95-36.49 392.32-238.05 395.75"
    );
  }
}

@keyframes createBgAnimation5 {
  0% {
    d: path(
      "M1108.82-3.9C969.92 12.83 891.46 322.39 655.99 324.91 420.53 327.43 429.58 254.91 203.17 254.91-23.25 254.91-135.11 324.7-249.66 324.91"
    );
  }
  100% {
    d: path(
      "M1227.95-36.42C1117.97-34.48 1020.54 105.68 811.58 105.75 602.61 105.82 603.39 35.75 395.2 35.75 187.02 35.75 84.38 105.51-21.17 105.75"
    );
  }
}

@media screen and (max-width: 768px) {


  .createTestForm {
    position: relative;
    height: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    z-index: 3;
    backdrop-filter: blur(2px);
    color: white;
  }


  .startCreateTest {
    box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
    -webkit-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
    -moz-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
    display: flex;
    height: 70vh;
    width: 85vw;
    flex-direction: column;
    animation: fadeIn 0.7s ease-in;
  }

  
  .startCreateTest p {
    width: 100%;
    margin: auto;
    font-size: 1.5em;
  }
  
  .startCreateTest input {
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 1em;
    border-bottom: 2px black solid;
    width: 80%;
    margin: auto;
    border-radius: 4px;
    color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.798);
  }

  .createOptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0c0f18;
    border-radius: 0px;
  }

  #createCategories {
    font-size: 1em;
    width: 80%;
    
  }

  .createDescription {
    display: flex;
    flex-direction: column;
    height: 70vh;
    box-shadow: 1px 5px 18px 2px rgba(0, 0, 0, 0.419);
    border-radius: 10px 10px 0px 0px;
    background-color: rgba(0, 0, 0, 0.798);
    animation: fadeIn 0.7s ease-in;
    margin: auto;
    width: 70vw;
  
  }

  .createImage {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
  }
  


  .descriptionFields{
    width: 100%;
    padding-bottom: 5vh;
  }
  .descriptionFields textarea {
    width: 50vw !important;
    height: fit-content;
    font-size: 1.5em;
  }

  .tooltip .top{
    display: none;
  }



  .descriptionFields h2{
    font-size: 1.5em !important;
  }



  .masterPageContainer button {
    width: 20vw;
  }

  .resultsH2 {
    font-size: 2.5em !important;
    margin: auto;
    padding-bottom: 0vh;
    color: white;
  }


  .resultsFormContainer {
    display: flex;
    /* margin: auto; */
    height: 250vh !important;

    padding-bottom: 2vh;
    flex-direction: column;
    justify-content: none !important;
    animation: fadeIn 1s ease-in;
    width: 70vw;
  }

  .nextButton{
    box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -webkit-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -moz-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  position: relative ;
    margin: auto;
    margin-top: 2vh;
    width: 120px;
    height: 45px;
    overflow: hidden;
    border: none;
    background-color: rgb(255, 255, 255);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-top-left-radius: 0px;
    cursor: pointer;
    border-bottom-right-radius: 0px;
    box-shadow: 0 0 10px rgb(104, 134, 218);
    
  }

  .nextButton::before {
    content: "";
    position: block;
    width: 15px;
    height: 200px;
    background: linear-gradient(#00ccff,#d400d4);
    animation: amm 4s linear infinite;
  }

  .nextButton::after {
    content: "";
    position: block;
    inset: 4px;
    background-color: rgb(17, 17, 17);

  }

  .customCard {
    /* box-shadow: 1px 1px 10px 1px; */
    height: 65vh;
    border-top: none !important;
    border-radius: 10px 10px 40px 40px;
    color: rgb(255, 255, 255);
    max-height: 60vh;
    width: 80vw;
    max-width: 80vw;
    margin-right: 1vw;
    background: #020305c6;
    margin-top: 5vh;
  }
  .svgLinesBg {
    z-index: 2;
    transform: scale(2.5);
    max-height: 100vh;
    position: fixed;
    max-width: 100vw;
    background: linear-gradient(
      300deg,
      #013b31,
      #378058,
      #185a81,
      #8a1e5a,
      #a9141b
    );
    background-size: cover;
    animation: gradient-animation 30s ease infinite;
  }
  .customCard:hover {
    transform: none;
    transition: 0.5s;
    z-index: 1;
  }
  .resultInputContainer {
    width: 100% !important;
    height: 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  
    margin-top: 4vh !important;
    margin: auto;
    /* opacity: 0; */
    /* transform: translateY(100%); */
    transition: transform 0.5s, opacity 0.5s;
  }

  .customCard:hover .resultInputContainer {
    opacity: 1;
    transform: translateY(0);
  }


}
