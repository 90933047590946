.aboutContainer {
  height: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 1px;
}

.aboutContainer h1 {
  font-size: 2em;
  font-family: "Julius Sans One", sans-serif;
  text-align: center;
}

.aboutContainer h2 {
  text-align: center;
  font-size: 1.5em !important;
  font-weight: bold;
}

.aboutBlurb {
  font-size: 1.5em;
  color: rgb(119, 119, 119);
  text-align: center;
  padding-bottom: 2vh;
  padding-left: 20vw;
  padding-right: 20vw;
}

.team {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
}

.teamMember {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Julius Sans One", sans-serif;
}

.teamMember p {
  text-align: center;
}

.team img {
  border-radius: 35px;
  width: 15rem;
  padding: 1rem;
}

.teamMember h3 {
  text-align: center;
  font-size: 2rem;
}

.teamMember svg {
  margin: auto;
}

.team svg {
  height: 5vh;
  border: black;
}

.aboutContainer h2 {
  font-size: 2em;
  font-family: "Julius Sans One", sans-serif;
}

.techSvgs {
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.techSvgs svg {
  height: 10vh;
  margin-top: 10px;
  border: black solid;
  margin-left: 2px;
  margin-right: 2px;
  padding: 1vh;
  /* animation: 3s linear 1s infinite alternate techBorderizer; */
  border-radius: 24px;
}

/* .techSvgs svg:hover {
  transform: scale(1.5);
  background-color: rgba(208, 208, 208, 0.338);
  cursor: pointer;
}

.techSvgs svg:nth-of-type(1) {
  height: 11.5vh;
  border: red solid;
  animation-delay: 0.1s;
}
.techSvgs svg:nth-of-type(2) {
  height: 10vh;
  border: red solid;
  animation-delay: 0.2s;
}
.techSvgs svg:nth-of-type(3) {
  height: 15vh;
  margin-top: 5vh;
  border: red solid;
  animation-delay: 0.3s;
}
.techSvgs svg:nth-of-type(4) {
  height: 10vh;
  margin-top: 5vh;
  border: red solid;

  animation-delay: 0.4s;
}
.techSvgs svg:nth-of-type(5) {
  height: 12.5vh;
  margin-top: 5vh;
  border: red solid;
  animation-delay: 0.5s;
} */

@keyframes techBorderizer {
  0% {
    border: #013b31 solid 5px;
  }
  25% {
    border: #378058 solid 5px;
  }
  50% {
    border: #185a81 solid 5px;
  }
  75% {
    border: #8a1e5a solid 5px;
  }
  100% {
    border: #a9141b solid 5px;
  }
}
@media screen and (max-width: 768px) {
  .aboutBlurb {
    font-size: 1em;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .techSvgs svg {
    height: 7vh;
    padding: 5px;
  }
}
