.homePageContainer {
  display: flex;
  align-items: column;
  height: 100%; /* Use viewport height for better sizing */
  animation: fadeIn 1.5 ease-in;
  background-color: #ffffff0a;
  margin-top: -50px;
}

.homePageContainer img {
  display: flex;
  align-self: center;
  filter: blur(0.5px);
  height: 100%;
  max-width: 50vw;
}

.homePageContainer p {
  color: rgb(0, 0, 0);
  font-size: 2.2rem;
  font-weight: lighter;
  text-shadow: none;
  -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

.textSection {
  font-weight: lighter;
  color: rgb(255, 255, 255);
  font-size: 5em !important;
  padding-left: 6vw;
  margin-left: 5vw;
  margin-top: 10%;
  padding-top: 5%;
  width: 50vw;

  background: linear-gradient(
    300deg,
    #013b31,
    #378058,
    #185a81,
    #8a1e5a,
    #a9141b
  );
  background-size: 300% 300%;
  animation: gradient-animation 30s ease infinite;
  text-shadow: none;
  height: 70vh;
  box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -webkit-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  -moz-box-shadow: 2px 5px 22px 1px rgba(0, 0, 0, 0.82);
  border-radius: 8px;
}

.typeFont {
  animation: fontChanger 14s infinite ease-in;
  padding-left: 2vw;
  font-weight: bolder;
}

.mainTypewriter {
  width: 40vw;
}

@keyframes fontChanger {
  0% {
    color: #023b30;
    /* font-family: "Techovier"; */
  }
  20% {
    color: #3b8057;
    /* font-family: "Techovier"; */
  }
  40% {
    color: #3397b6;
  }
  60% {
    /* font-family: "moto-verse"; */
    color: #863969;
  }
  80% {
    /* font-family: "moto-verse"; */
    color: #e84941;
  }
  100% {
    /* font-family: "moto-verse"; */
    color: #9a4133;
  }
}

@media screen and (max-width: 768px) {
  .homePageContainer {
    margin-top: 0px;
    height: 80vh;
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .homePageContainer img {
    display: none; /* Hide image on mobile */
  }

  .textSection {
    padding-left: 20px;
    padding-top: 25%;
    width: 90vw;
    height: 65vh;
    font-size: 3em !important;
    box-shadow: none;
  }

  .homePageContainer p {
    font-size: 1.8rem;
  }

  .typeFont {
    font-size: 2.4rem;
  }

  .Typewriter {
    width: 95vw;
    font-size: 1.8rem;
  }
}
