.animaTitle{
    font-size: 2.5em !important;
    font-family: Julius Sans One ;
    color: rgb(0, 0, 0);
    transition: .3s ease-in
}

.darkModeTitle{
    font-size: 2.5em !important;
    font-family: Julius Sans One ;
    color: rgb(255, 255, 255);
    transition: .3s ease-in
}

.darkMode{
    color: white !important;
}

.navStyle{
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(10px);
}

.navItems:hover{
    animation: hoverAnimation 4s ease-in-out infinite;
}


.navItemsContainer{
    background-color: rgba(255, 0, 0, 0);
}
.navItemsContainer ul{
    background-color: #00000000;
    color: rgb(0, 0, 0);
    transition: .3s ease-in;
    font-size: 1em;
}

.animaTitle:hover{
    animation: hoverAnimation 4s ease-in-out infinite;
}
@keyframes hoverAnimation {
    0%{text-decoration: underline solid #4ab5bd}
    25%{text-decoration:underline  solid black;}
    50%{text-decoration: underline solid #4ab5bd}
    100%{text-decoration:underline solid black;}
}

@media screen and (max-width: 768px) {
    .animaTitle{
        font-size: 1.6em !important;
    }
  }