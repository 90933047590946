resultCard {
  animation: bgshift 14s linear infinite alternate;
  margin-top: 10vh !important;
}

@keyframes bgshift {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

#resultsItems {
  background-color: rgba(255, 255, 255, 0.682) !important;
  display: flex;
  flex-direction: column;
  width: 22vw;
  margin: auto;
  border-radius: 20px;
  backdrop-filter: invert(80%);
  filter: none !important
}

#retakeButton {
  width: fit-content;
  margin: auto;
}

.loadingSvg {
  fill: red;
  padding: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: 3s ease-in svgColorizer infinite;
}

.resultName{

  /* text-align: center !important; */
}

@keyframes svgColorizer {
  0% {
    fill: #013b31;
  }
  25% {
    fill: #378058;
  }
  50% {
    fill: #185a81;
  }
  75% {
    fill: #8a1e5a;
  }
  100% {
    fill: #4d1e20;
  }
}


@media screen and (max-width: 1227px) {
  .mainPageCards:hover {
    transform: none;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    width: 35%;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

  .resultName{
    text-align: center !important;
  }
}

@media screen and (max-width: 751px) {

  .mainPageCards:hover {
    transform: none;
  }
  .resultName p{
    font-size: 1em;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

  .resultName{
    text-align: center !important;
  }
}

@media screen and (max-width: 724px) {

  .mainPageCards:hover {
    transform: none;
  }
  .resultName p{
    font-size: 1em;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

  .resultName{
    text-align: center !important;
  }
}


@media screen and (max-width: 744px) {

  .mainPageCards:hover {
    transform: none;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    width: 55%;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

  .resultName{
    text-align: center !important;
  }
}

.loadingButton{
  color: white;
  position: absolute;
  margin: auto
}


@media screen and (max-width: 433px) {

  .mainPageCards:hover {
    transform: none;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

}


@media screen and (max-width: 428px) {

  .mainPageCards:hover {
    transform: none;
  }
  #resultsItems {
    background-color: rgba(255, 255, 255, 0.682) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    backdrop-filter: invert(80%);
    filter: none !important
  }

  .resultName{
    text-align: center !important;
  }
}